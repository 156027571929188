<template>
  <div id="searchbar">
    <input type="text" id="search_field" v-on:keyup.enter="searchDestinations()" placeholder="search" />
    <button type="submit" class="button small" @click="searchDestinations()">
      <inline-svg
        :src="require('../../../assets/icons/search.svg')"
      ></inline-svg>
      Search
    </button>
  </div>

  <l-map
      @ready="mapReady"
      @dragend="searchDestinations"
      @zoomend="searchDestinations"
      ref="map"
      v-model="zoom"
      v-model:zoom="zoom"
      v-model:detectRetina="detectRetina"
      :inertia="true"
      :inertiaDeceleration="3000"
      :inertiaMaxSpeed="Infinity"
      :easeLinearity="0.2"
      :zoomAnimation="true"
      :options="{ zoomControl: false }" >
    <l-tile-layer
        :options="{ reuseTiles: true }"
        v-model:zoomOffset="zoomOffset"
        v-model:detectRetina="detectRetina"
        url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJpdnZlbCIsImEiOiJjajhtcnh6NG8wdXhuMnFxaHA0Z3U1YmFvIn0.s2VmrlVvi88paGlBcORStw"
      ></l-tile-layer>
      <l-marker :lat-lng="marker.location" v-for="marker in markers" :key="marker.id">
        <l-popup>
          <img
            @click.prevent="showDestination(marker.id)"
            :src="`https://destinations.trivvel.com/destinations/${ marker.id }/default.jpg`" />
          <a class="add" @click.prevent="addDestination()">
            <span>+</span>
          </a>
          <h2 class="name">{{ truncateText(marker.name, 30) }}</h2>
        </l-popup>
      </l-marker>

    </l-map>

</template>

<script>
// import "leaflet/dist/leaflet.css"
// import L from "leaflet";
// import Marker from '@/components/Marker.vue'

import "leaflet/dist/leaflet.css"
import { LMap, LIcon, LTileLayer, LMarker, LPopup } from "@vue-leaflet/vue-leaflet";
import { useTripStore } from '../../../store/trip';

export default {
   components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },
  data() {
    return {
      tripStore: useTripStore(),
      map: '',
      zoom: 6,
      tileSize: 256,
      zoomOffset: -1,
      detectRetina: true,
      iconWidth: 25,
      iconHeight: 40,
      search_query: '',
      markers: [],
      markerPopups: [],
      coordinates: [-111.549668, 39.014],
      mapbox: {},
      accessToken: 'pk.eyJ1IjoidHJpdnZlbCIsImEiOiJjajhtcnh6NG8wdXhuMnFxaHA0Z3U1YmFvIn0.s2VmrlVvi88paGlBcORStw',
      mapStyle: 'mapbox://styles/mapbox/streets-v11'
    };
  },
  watch: {
    '$store.state.trip.current': function() {
      var trip = this.tripStore.current.trip;
      if(this.map) {
        this.map.setView([parseFloat(trip.latitude), parseFloat(trip.longitude)], trip.zoom_level);
      }
    }
  },
  mounted() { },
  methods: {
    addDestination(destination_id) {
      var destination = this.tripStore.loadDestination(destination_id).then(response => console.log(response));
      //this.tripStore.addDestination(this.tripStore.current.trip.id, destination);
    },
    truncateText(text, length) {
      if (text.length <= length) {
        return text;
      }

      return text.substr(0, length) + '\u2026'
    },
    showDestination(id) {
      this.$router.push({ path: `/trips/${this.$route.params.id}/destinations/${id}` });
    },
    mapReady() {
      this.map = this.$refs.map.leafletObject;
      var trip = this.tripStore.current.trip;
      if(trip.id) {
        this.map.setView([parseFloat(trip.latitude), parseFloat(trip.longitude)], trip.zoom_level);
        this.searchDestinations();
      }
    },
    searchDestinations() {
       var self = this
       var bounds = this.map.getBounds();
    //   var destination_ids = [];

    //   var greenIcon = L.icon({
    //     iconUrl: '/marker-icon.png',
    //     iconSize:     [30, 45],
    //     iconAnchor:   [15, 44],
    //     popupAnchor:  [0, -35]
    //   });

    //   function truncateText(text, length) {
    //     if (text.length <= length) {
    //       return text;
    //     }

    //     return text.substr(0, length) + '\u2026'
    //   }
      self.markers = [];
      self.search_query = document.getElementById("search_field").value;
      fetch("https://destinations.trivvel.com/?q=" + self.search_query + "&nw=" + bounds._northEast.lat + "," + bounds._southWest.lng + "&se=" + + bounds._southWest.lat + "," + bounds._northEast.lng)
         .then(response => response.json())
         .then(data => {
           for (var i = 0; i < data.hits.length; i++) {
             self.markers.push(data.hits[i]._source)
           }
         });

    //     self.markers.forEach(function(marker) { self.map.removeLayer(marker) });
    //     var html = '';
    //     for (var i = 0; i < data.hits.length; i++) {

    //       self.markerPopups.push({
    //         id: data.hits[i].document.id,
    //         data: data.hits[i].document
    //       });

    //      // if ($.inArray(data.hits[i]._source.id, destination_ids) != -1)
    //      // {
    //        // var marker = new mapboxgl.Marker({color: '#41D4A5'});
    //       //  $("#discover").append('<li><h3>' + data.hits[i]._source.name + '</h3><input onclick="vm.showDestination(event, ' + data.hits[i]._source.id + ')" class="button" type="button" value="View"/></li>');
    //       //  html = '<h2>' + data.hits[i]._source.name + '</h2><input onclick="vm.showDestination(event, ' + data.hits[i]._source.id + ')" class="button" type="button" value="+"/>';
    //       //} else {
    //       //  var marker = new mapboxgl.Marker({color: '#41D4A5'});
    //         //$("#discover").append('<li><h3>' + data.hits[i]._source.name + '</h3><input onclick="vm.showDestination(this, ' + data.hits[i]._source.id + ');" class="button" type="button" value="View"/></li>');
    //        // onerror="this.className = \'icon\'; this.src=\'/assets/map_icons/' + '' + '.svg\';"
    //         html = '<img  onclick="vm.showDestination(this, ' + data.hits[i].document.id + ');" src="https://destinations.trivvel.com/destinations/' + data.hits[i].document.id + '/default.jpg"/><a class="add" onclick="vm.showDestination(this, ' + data.hits[i].document.id + ');"><span>+</span></a><h2 class="name">' + truncateText(data.hits[i].document.name, 20) + '</h2>';
    //      // html = this.$refs.foo.$el;
    //     // }
    //       var marker = L.marker(data.hits[i].document.location, { icon: greenIcon })
    //                     .bindPopup(html);

    //       self.markers.push(marker);
    //       self.map.addLayer(marker);
    //     }
    //   });
    }
  }
}
</script>
<style lang="sass">
.leaflet-container
  right: -155px
  left: 75px
  bottom: 0
  position: fixed
  z-index: 10
  top: 80px
  height: auto

  @media (max-width: $media-mobile)
    right: 0
    left: 0
    top: 2rem

.leaflet-popup
  padding: 0
  width: 170px
  height: 55px

  .leaflet-popup-content
    height: 45px
    display: flex
    align-items: center

    img
      border: 8px solid white
      border-radius: 60px
      width: 80px
      height: 80px
      position: absolute
      margin-top: -24px
      margin-left: -72px
      box-shadow: 0 2px 4px rgba(0,0,0,.5)

      &.icon
        padding: 10px
        background-color: $primary-color

    h2
      font-size: 1.5rem
      font-weight: 400
      padding-left: 13px
      height: 50px
      margin: 0
      line-height: 1.7rem

    a.add
      position: absolute
      margin-left: 128px
      width: 40px
      height: 40px
      margin-top: 23px
      padding: 0
      background-color: $primary-color
      border-radius: 60px
      display: block
      cursor: pointer
      top: 20px

      &:hover
        background-color: $secondary-color

      span
        color: white
        font-size: 3.8rem
        font-weight: 600
        margin-left: 8px
        display: block
        position: relative
        top: -11px

.leaflet-popup-close-button
  display: none
</style>
<style lang="sass" scoped>
.content
  padding-left: 0

#searchbar
  position: fixed
  top: 0
  height: 80px
  z-index: 800
  left: 75px
  background-color: #F8FFFA
  right: 0
  display: flex
  align-items: center
  padding-left: 2rem
  border-bottom: 1px solid #AFDDBC

  @media (max-width: $media-mobile)
    margin-top: 2rem
    background: none
    border: 0
    left: 0

  #search_field
    width: 30%
    margin: 0
    margin-right: 2rem

    @media (max-width: $media-mobile)
      width: 60%

  .button
    margin: 0


</style>
